











































































import Vue from "vue";
import { dispatch, WWWConfig } from "@/store";
import moment from "moment";
import { WWWWProductStatus } from "@/enum";
import WWWRequestList from "./WWWRequestList.vue";

export default Vue.extend({
  components: {
    WWWRequestList
  },
  data() {
    return {
      dispatch,
      loading: false,
      nameKeyword: "",
      list: [] as WWWConfig[],
      visible: false,
      isAdd: false,
      model: {} as WWWConfig,
      requestVisible: false
    };
  },
  computed: {
    fields() {
      return [
        {
          title: "商品名称",
          dataIndex: "name",
          rules: [
            {
              required: true,
              whitespace: true,
              message: "请输入商品名称",
              trigger: "blur"
            },
            {
              max: 40,
              message: "请输入少于 40 个字符",
              trigger: "blur"
            }
          ]
        },
        {
          title: "国内出售状态",
          dataIndex: "cn_status",
          type: "radio",
          options: WWWWProductStatus,
          rules: [
            {
              required: true,
              message: "请选择出售状态"
            }
          ],
          customRender: (text: string) => (WWWWProductStatus as any)[text]
        },
        {
          title: "国内购买链接",
          dataIndex: "cn_url",
          rules: [
            {
              required: true,
              whitespace: true,
              message: "请输入购买链接",
              trigger: "blur"
            },
            {
              validator: (rule: any, value: string, callback: Function) => {
                if (!/^https?:\/\/.*$/.test(value)) {
                  callback("请输入正确的购买链接");
                  return;
                }
                callback();
              }
            }
          ]
        },
        {
          title: "国内按钮文字",
          dataIndex: "cn_button",
          extra:
            "首页按钮文字，国内出售状态为“站外跳转”时，不填写此项会隐藏按钮"
        },
        {
          title: "国内首页展示",
          dataIndex: "cn_is_home",
          type: "switch",
          scopedSlots: { customRender: "home" },
          sorter: (a: WWWConfig, b: WWWConfig) =>
            a.cn_is_home && !b.cn_is_home ? -1 : 1
        },
        {
          title: "国内首页展示图片",
          dataIndex: "cn_home_media_list",
          type: "media",
          length: 2,
          extra:
            "第 1 张图为 PC 端图片，第 2 张图为移动端图片，可以拖动进行排序"
        },
        {
          title: "国内置顶",
          dataIndex: "cn_is_top",
          type: "switch",
          scopedSlots: { customRender: "top" },
          extra: "置顶后将在官网顶部导航展示",
          sorter: (a: WWWConfig, b: WWWConfig) =>
            a.cn_is_top && !b.cn_is_top ? -1 : 1
        },
        {
          title: "国内置顶名称",
          dataIndex: "cn_top_name",
          rules: [
            {
              required: true,
              whitespace: true,
              message: "请输入国内置顶名称",
              trigger: "blur"
            },
            {
              max: 40,
              message: "请输入少于 40 个字符",
              trigger: "blur"
            }
          ]
        },
        {
          title: "国内排序权重",
          dataIndex: "cn_priority",
          type: "number",
          min: 0,
          precision: 0,
          extra: "权重越大的越靠前，影响首页展示、置顶的顺序",
          sorter: (a: WWWConfig, b: WWWConfig) =>
            (b.cn_priority || 0) - (a.cn_priority || 0)
        },
        {
          title: "海外出售状态",
          dataIndex: "overseas_status",
          type: "radio",
          options: WWWWProductStatus,
          rules: [
            {
              required: true,
              message: "请选择出售状态"
            }
          ],
          customRender: (text: string) => (WWWWProductStatus as any)[text]
        },
        {
          title: "海外购买链接",
          dataIndex: "overseas_url",
          rules: [
            {
              required: true,
              whitespace: true,
              message: "请输入购买链接",
              trigger: "blur"
            },
            {
              validator: (rule: any, value: string, callback: Function) => {
                if (!/^https?:\/\/.*$/.test(value)) {
                  callback("请输入正确的购买链接");
                  return;
                }
                callback();
              }
            }
          ]
        },
        {
          title: "海外按钮文字",
          dataIndex: "overseas_button",
          extra:
            "首页按钮文字，海外出售状态为“站外跳转”时，不填写此项会隐藏按钮"
        },
        {
          title: "海外首页展示",
          dataIndex: "overseas_is_home",
          type: "switch",
          scopedSlots: { customRender: "home" },
          sorter: (a: WWWConfig, b: WWWConfig) =>
            a.overseas_is_home && !b.overseas_is_home ? -1 : 1
        },
        {
          title: "海外首页展示图片",
          dataIndex: "overseas_home_media_list",
          type: "media",
          length: 2,
          extra:
            "第 1 张图为 PC 端图片，第 2 张图为移动端图片，可以拖动进行排序"
        },
        {
          title: "海外置顶",
          dataIndex: "overseas_is_top",
          type: "switch",
          scopedSlots: { customRender: "top" },
          extra: "置顶后将在官网顶部导航展示",
          sorter: (a: WWWConfig, b: WWWConfig) =>
            a.overseas_is_top && !b.overseas_is_top ? -1 : 1
        },
        {
          title: "海外置顶名称",
          dataIndex: "overseas_top_name",
          rules: [
            {
              required: true,
              whitespace: true,
              message: "请输入海外置顶名称",
              trigger: "blur"
            },
            {
              max: 40,
              message: "请输入少于 40 个字符",
              trigger: "blur"
            }
          ]
        },
        {
          title: "海外排序权重",
          dataIndex: "overseas_priority",
          type: "number",
          min: 0,
          precision: 0,
          extra: "权重越大的越靠前，影响首页展示、置顶的顺序",
          sorter: (a: WWWConfig, b: WWWConfig) =>
            (b.overseas_priority || 0) - (a.overseas_priority || 0)
        },
        {
          title: "商品编码",
          dataIndex: "code",
          extra: "商品编码不能重复",
          rules: [
            {
              required: true,
              whitespace: true,
              message: "请输入商品编码",
              trigger: "blur"
            },
            {
              max: 40,
              message: "请输入少于 40 个字符",
              trigger: "blur"
            },
            {
              validator: (rule: any, value: string, callback: Function) => {
                if (!/^[A-Z0-9\s]*$/.test(value)) {
                  callback("商品编码只能为大写字母和数字组合");
                  return;
                }
                callback();
              }
            }
          ],
          immutable: true
        }
      ];
    },
    columns() {
      let columns: any = this.fields
        .slice()
        .filter(item =>
          [
            "name",
            "cn_status",
            "cn_is_home",
            "cn_is_top",
            "cn_priority",
            "overseas_status",
            "overseas_is_home",
            "overseas_is_top",
            "overseas_priority"
          ].includes(item.dataIndex)
        );
      columns = columns.concat([
        {
          title: "创建时间",
          dataIndex: "create_time",
          scopedSlots: { customRender: "time" },
          sorter: (a: WWWConfig, b: WWWConfig) =>
            moment(a.create_time).isBefore(b.create_time) ? -1 : 1
        },
        {
          title: "更新时间",
          dataIndex: "update_time",
          scopedSlots: { customRender: "time" },
          sorter: (a: WWWConfig, b: WWWConfig) =>
            moment(a.update_time).isBefore(b.update_time) ? -1 : 1
        },
        {
          title: "操作",
          key: "actions",
          width: "140px",
          align: "center",
          scopedSlots: { customRender: "actions" }
        }
      ]);
      return columns;
    },
    dataSource(): WWWConfig[] {
      if (this.nameKeyword) {
        return this.list.filter((item: WWWConfig) =>
          item.name.toLowerCase().includes(this.nameKeyword.toLowerCase())
        );
      }
      return this.list;
    },
    requertFields() {
      return [];
    }
  },
  methods: {
    getList() {
      this.loading = true;
      dispatch
        .settingsGetWwwConfig()
        .then((res: WWWConfig[]) => {
          this.list = res.sort((a, b) => b.cn_priority - a.cn_priority);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onAdd() {
      this.visible = true;
      this.isAdd = true;
      this.model = {
        id: new Date().getTime()
      } as WWWConfig;
    },
    onEdit(config: WWWConfig) {
      this.visible = true;
      this.isAdd = false;
      this.model = config;
    },
    add(config: WWWConfig) {
      return new Promise((resolve, reject) => {
        config.create_time = moment().format();
        let item = this.list.find(item => item.id === config.id);
        while (item) {
          config.id = new Date().getTime();
          item = this.list.find(item => item.id === config.id);
        }
        item = this.list.find(item => item.code === config.code);
        if (item) {
          this.$message.error("存在重复的商品编码：" + item.code);
          reject();
        }
        const list = [config, ...this.list];
        list.sort((a, b) => b.cn_priority - a.cn_priority);
        dispatch
          .settingsPatchWwwConfig(list)
          .then(resolve)
          .catch(reject);
      });
    },
    edit(config: WWWConfig) {
      return new Promise((resolve, reject) => {
        config.update_time = moment().format();
        const index = this.list.findIndex(item => item.id === config.id);
        let list: WWWConfig[] = [];
        if (index < 0) {
          list = [config, ...this.list];
        } else {
          list = [...this.list];
          list[index] = config;
        }
        list.sort((a, b) => b.cn_priority - a.cn_priority);
        dispatch
          .settingsPatchWwwConfig(list)
          .then(resolve)
          .catch(reject);
      });
    },
    onOk() {
      this.visible = false;
      this.model = {} as WWWConfig;
      this.getList();
    },
    onShowRequest(config: WWWConfig) {
      this.requestVisible = true;
      this.model = config;
    }
  },
  mounted() {
    this.getList();
  }
});
